import { memo } from 'react';
import { Global } from '@emotion/react';
import tw, { GlobalStyles as BaseStyles, css } from 'twin.macro';
import 'react-toastify/dist/ReactToastify.css';

import tailwindConfig from '../../tailwind.config';

const customStyles = css`
  @font-face {
    font-family: 'Saira-Fallback';
    size-adjust: 104.18%;
    src: local('Arial');
  }

  @font-face {
    font-family: 'Maven-Pro-Fallback';
    size-adjust: 103.25%;
    src: local('Arial');
  }

  @font-face {
    font-family: 'Saira';
    font-style: normal;
    font-weight: ${tailwindConfig.theme.fontWeight.regular};
    src:
      url('/fonts/saira-variable.woff2') format('woff2 supports variations'),
      url('/fonts/saira-variable.woff2') format('woff2-variations'),
      url('/fonts/saira-variable.ttf') format('ttf supports variations');
    font-weight: 100 900;
    text-rendering: optimizeLegibility;
    font-display: swap;
  }

  @font-face {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: ${tailwindConfig.theme.fontWeight.regular};
    src:
      url('/fonts/maven-pro-variable.woff2') format('woff2 supports variations'),
      url('/fonts/maven-pro-variable.woff2') format('woff2-variations'),
      url('/fonts/maven-pro-variable.ttf') format('ttf supports variations');
    font-weight: 400 900;
    text-rendering: optimizeLegibility;
    font-display: swap;
  }

  html {
    font-size: 16px;
  }

  body {
    ${tw`font-saira antialiased leading-5`};
    -webkit-tap-highlight-color: transparent;
  }

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`font-saira`};
  }

  pre,
  code {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
      'Courier New', monospace;
  }

  button {
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
  }

  .u-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .u-scroll-forbid {
    inset: 0px;
    height: 100%;
    margin: 0px;
    overflow-y: hidden;
    position: fixed;
  }

  [data-rmiz-btn-unzoom] {
    ${tw`bg-primary-p1`}
  }

  :root {
    --toastify-color-info: ${tailwindConfig.theme.colors.secondary.s3};
    --toastify-color-success: ${tailwindConfig.theme.colors.secondary.s1};
    --toastify-color-error: ${tailwindConfig.theme.colors.primary.p2};
    --toastify-toast-width: 448px;
    --toastify-font-family: 'Maven Pro', sans-serif;
    --toastify-text-color-info: ${tailwindConfig.theme.colors.primary.p1};
    --toastify-text-color-success: ${tailwindConfig.theme.colors.primary.p1};
    --toastify-text-color-warning: ${tailwindConfig.theme.colors.primary.p1};
    --toastify-text-color-error: ${tailwindConfig.theme.colors.primary.p1};
  }
  .Toastify__toast {
    padding: 15px;
  }
  .Toastify__toast-body {
    font-weight: 500;
    padding-bottom: 20px;
  }
  .Toastify__progress-bar {
    bottom: 15px;
    margin: 0 20px;
    width: calc(100% - 40px);
  }
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
    ${tw`bg-primary-p1`}
  }
  .Toastify__close-button {
    ${tw`text-primary-p1`}
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
);

export default memo(GlobalStyles);
